import { useCallback, useState } from 'react'
import { utils } from 'ethers'
import * as circomlib from 'circomlib'
import { findId, leBuff2int } from '../utils'

// Enter a valid infura key here to avoid being rate limited
// You can get a key for free at https://infura.io/register

function unpack(code: string):
  | {
      R8: bigint[]
      S: bigint
      id: bigint
    }
  | undefined {
  let sig: string
  let id: bigint
  if (code.length === 130) {
    id = BigInt(findId(code))
    sig = code
  } else if (code.length > 130) {
    sig = code.slice(0, 130)
    id = BigInt(`0x${code.slice(130)}`)
  } else {
    return undefined
  }
  const data = utils.arrayify(sig)
  const point = circomlib.babyJub.unpackPoint(data.slice(0, 32))
  const S = leBuff2int(data.slice(32))
  return { R8: point, S, id: BigInt(id) } as any
}

function useProof(redeemCode?: string): {
  proof: any
  isComputing: boolean
  code: string
  tokenId: bigint | undefined
  computeProof: (account: string) => Promise<void>
  setCode: React.Dispatch<React.SetStateAction<string>>
} {
  const [proof, setProof] = useState()
  const [isComputing, setIsComputing] = useState(false)
  const [code, setCode] = useState(redeemCode || '')

  const unpackedData = unpack(code)
  const { snarkjs } = window as any

  // Open wallet selection modal.
  const computeProof = useCallback(
    async (account: string) => {
      if (!unpackedData) {
        throw Error('Invalid redeem code')
      }
      const { id, R8, S } = unpackedData
      try {
        setIsComputing(true)
        const { proof, publicSignals } = await snarkjs.groth16.fullProve(
          {
            tokenId: id,
            account: account,
            sigS: S,
            sigR8x: R8[0],
            sigR8y: R8[1],
          },
          'snark/claimer.wasm',
          'snark/claimer_final.zkey'
        )
        const vkey = await fetch('snark/verification_key.json').then(function (
          res
        ) {
          return res.json()
        })
        const res = await snarkjs.groth16.verify(vkey, publicSignals, proof)
        if (!res) {
          throw Error('Invalid proof')
        } else {
          setProof(proof)
        }
      } catch (e) {
        throw e
      } finally {
        setIsComputing(false)
      }
      setIsComputing(false)
    },
    [unpackedData, snarkjs]
  )

  return {
    proof,
    isComputing,
    tokenId: unpackedData?.id,
    code,
    computeProof,
    setCode,
  }
}

export default useProof
