import React from 'react'
import { ChainId, DAppProvider, Config } from '@usedapp/core'
import ReactDOM from 'react-dom'
import './index.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import reportWebVitals from './reportWebVitals'

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]:
      'https://eth-mainnet.alchemyapi.io/v2/iDYMsuBrsziw4ZBbzCnZOJcKedK0iQ-0',
  },
}

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
