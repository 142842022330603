import React, { useState } from 'react'
import { useEthers } from '@usedapp/core'
import { SolNSop__factory } from '@solnsop/contracts'
import useQuery from '../hooks/useQuery'
import useProof from '../hooks/useProof'

function Home() {
  const { activateBrowserWallet, account, library } = useEthers()
  const [idx, setIndex] = useState<number>(Math.floor(Math.random() * 32))
  const [message, setMessage] = useState<string>('')
  const [txHash, setTxHash] = useState<string>()
  const [txStatus, setTxStatus] = useState<string>()
  const query = useQuery()
  const { proof, isComputing, tokenId, code, computeProof, setCode } = useProof(
    query.get('code') || undefined
  )

  return (
    <div>
      <div id="main">
        <header id="header">
          <h1>Sol & Sop Wedding NFT</h1>
          {/* <p>Sol & Sop Wedding NFT</p> */}
          <img src="images/ethadd.png" alt="" />
          <p style={{ fontSize: 10 }}>
            솔&섭 이더리움 주소: 0x95E53abb5E1AAe23CB3Be4EE537a41aB5A7a9c56 /
            solnsop.eth
          </p>
        </header>
        <div style={{ fontSize: 16 }}>
          <section id="nft" style={{ padding: '0.75em', textAlign: 'left' }}>
            <span>선택한 사진:</span>
            <img
              src={`images/nfts/${idx + 1}.jpg`}
              alt=""
              style={{ width: '100%' }}
            />
          </section>
          <section id="thumbnails">
            {Array(32)
              .fill(undefined)
              .map((_, i) => `images/thumbnails/${i + 1}.jpg`)
              .map((img, i) => (
                <article
                  className={i === idx ? 'active' : ''}
                  style={{ width: '12.5%' }}
                >
                  <span
                    className="thumbnail"
                    data-position="left center"
                    onClick={() => setIndex(i)}
                  >
                    <img src={img} alt="" style={{ cursor: 'pointer' }} />
                  </span>
                </article>
              ))}
          </section>
          <section id="redeem" style={{ padding: '0.75em', textAlign: 'left' }}>
            <span>Redeem 코드:</span>
            <br />
            <textarea onChange={(e) => setCode(e.target.value)}>
              {code}
            </textarea>
          </section>
          <section
            id="account"
            style={{ padding: '0.75em', textAlign: 'left' }}
          >
            <span>계정:</span>
            <br />
            {account ? (
              <span>{account}</span>
            ) : (
              <button
                onClick={() =>
                  activateBrowserWallet((e) => {
                    if ('NoEthereumProviderError' === e.name) {
                      alert('메타마스크를 설치해주세요.')
                    }
                  })
                }
              >
                Connect Metamask
              </button>
            )}
          </section>
          <section id="gen" style={{ padding: '0.75em', textAlign: 'left' }}>
            <span>NFT 민팅 코드 생성하기</span>
            <br />
            <button
              onClick={() => {
                if (account) {
                  computeProof(account)
                    .then(() => {
                      console.log('success')
                    })
                    .catch((e) => {
                      console.error(e)
                      alert(`Failed to generate SNARK: ${e.name}`)
                    })
                } else {
                  alert('Connect wallet first.')
                }
              }}
            >
              {isComputing
                ? 'computing...'
                : proof
                ? 'Proof generated!'
                : 'Compute zk-SNARK'}
            </button>
            <br />
          </section>
          <section id="mint" style={{ padding: '0.75em', textAlign: 'left' }}>
            <span>NFT 민트하기</span>
            <br />

            <textarea
              onChange={(e) => setMessage(e.target.value)}
              placeholder={`메세지를 남겨주세요.`}
            >
              {message}
            </textarea>
            <br />
            <button
              onClick={() => {
                if (!library) {
                  alert('Connect Metamask.')
                } else if (proof && tokenId) {
                  const contract = SolNSop__factory.connect(
                    `0xce4a4726a1d043c5be1dd534371c5d089f090d9f`,
                    library
                  )
                  console.log(proof)
                  contract
                    .connect(library.getSigner(account || undefined))
                    .mint(
                      tokenId,
                      proof.pi_a.slice(0, 2),
                      proof.pi_b
                        .slice(0, 2)
                        .map((arr: BigInt[]) => arr.reverse()),
                      proof.pi_c.slice(0, 2),
                      idx,
                      message
                    )
                    .then((receipt) => {
                      setTxHash(receipt.hash)
                      setTxStatus(`Pending`)
                      receipt.wait(1).then(() => {
                        setTxStatus(`Confirmed`)
                        alert(`Minted ${tokenId}`)
                      })
                    })
                    .catch((e) => {
                      setTxStatus(`Failed: ${e.data.message}`)
                      setTxHash(undefined)
                    })
                } else {
                  alert('Compute zk-SNARK first.')
                }
              }}
            >
              Mint
            </button>
            <br />
            <p>{txStatus}</p>
            <a
              href={`https://etherscan.io/tx/${txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              {txHash}
            </a>
          </section>
        </div>
        <footer id="footer">
          <ul className="copyright">
            <li>&copy; Sol & Sop.</li>
          </ul>
        </footer>
      </div>
      <div id="viewer">
        <div className="inner">
          <div
            className="nav-next"
            onClick={() => {
              setIndex(Math.min(31, idx + 1))
            }}
          ></div>
          <div
            className="nav-previous"
            onClick={() => {
              setIndex(Math.max(0, idx - 1))
            }}
          ></div>
          {/* <div className="toggle"></div> */}
        </div>
        <div className="slide active">
          <div className="caption"></div>
          <div
            className="image"
            style={{
              backgroundPosition: 'center top',
              backgroundImage: `url(images/fulls/${idx + 1}.png)`,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Home
