export function findId(sig: string): number {
  const pregenCodes = [
    0x24ed, 0xa57c, 0xb9a1, 0x79e9, 0xd576, 0xae6e, 0x8dbd, 0xf8ed, 0x4b51,
    0x786a, 0x440d, 0x2045, 0xb617, 0x5cd0, 0x619e, 0x7f16, 0x3a6f, 0x5ccb,
    0x9c97, 0x5202, 0x610f, 0xd98b, 0x290a, 0xf5ae, 0xfd2b, 0x2ead, 0x5f3a,
    0x6acc, 0x70be, 0x5bb1, 0x2cf4, 0x8b07,
  ]
  return pregenCodes.findIndex((v) => v === parseInt(sig.slice(0, 6), 16))
}

export function leBuff2int(buff: {
  buffer: ArrayBufferLike
  byteOffset: number | undefined
  byteLength: number | undefined
  length: number
}) {
  let res = BigInt(0)
  let i = 0
  const buffV = new DataView(buff.buffer, buff.byteOffset, buff.byteLength)
  while (i < buff.length) {
    if (i + 4 <= buff.length) {
      res += BigInt(buffV.getUint32(i, true)) << BigInt(i * 8)
      i += 4
    } else if (i + 4 <= buff.length) {
      res += BigInt(buffV.getUint16(i, true)) << BigInt(i * 8)
      i += 2
    } else {
      res += BigInt(buffV.getUint8(i)) << BigInt(i * 8)
      i += 1
    }
  }
  return res
}
